import React, { useState } from 'react'
import Select from 'react-select';
import { countries } from './countryCodes'
import { Loader2 } from 'lucide-react';
import axios from 'axios';

const activity = [
    { label: "Acounting & Auditing", value: "Acounting & Auditing" },
    { label: "Advertising", value: "Advertising" },
    { label: "Automobile Trading", value: "Automobile Trading" },
    { label: "Aviation Consultancy", value: "Aviation Consultancy" },
    { label: "Buisness Consultancy", value: "Buisness Consultancy" },
    { label: "Cleaning Services", value: "Cleaning Services" },
    { label: "Creative Arts & Entertainment Activities", value: "Creative Arts & Entertainment Activities" },
    { label: "Delivery Services", value: "Delivery Services" },
    { label: "Digital Marketing", value: "Digital Marketing" },
    { label: "E-commerce", value: "E-commerce" },
    { label: "Educational Support Activities", value: "Educational Support Activities" },
    { label: "Engineering Consultancy", value: "Engineering Consultancy" },
    { label: "Event Management", value: "Event Management" },
    { label: "Food Trading", value: "Food Trading" },
    { label: "General Trading", value: "General Trading" },
    { label: "Holding Company", value: "Holding Company" },
    { label: "Hospitality Management", value: "Hospitality Management" },
    { label: "HR Consultancy", value: "HR Consultancy" },
    { label: "Interior Design", value: "Interior Design" },
    { label: "Investment Consultancy", value: "Investment Consultancy" },
    { label: "IT Consultancy", value: "IT Consultancy" },
    { label: "IT Trading", value: "IT Trading" },
    { label: "Jwellery Trading", value: "Jwellery Trading" },
    { label: "Legal Consultancy", value: "Legal Consultancy" },
    { label: "Maintenance", value: "Maintenance" },
    { label: "Management Consultancy", value: "Management Consultancy" },
    { label: "Media", value: "Media" },
    { label: "Perfumes & Cosmetics Trading", value: "Perfumes & Cosmetics Trading" },
    { label: "Photography", value: "Photography" },
    { label: "PR", value: "PR" },
    { label: "Project Management", value: "Project Management" },
    { label: "Raw Materials Trading", value: "Raw Materials Trading" },
    { label: "Real Estate", value: "Real Estate" },
    { label: "Restaurant, Cafe, Cloud Kitchen", value: "Restaurant, Cafe, Cloud Kitchen" },
    { label: "Retail Outlet/Shop", value: "Retail Outlet/Shop" },
    { label: "Salon", value: "Salon" },
    { label: "Software Publishing", value: "Software Publishing" },
    { label: "Sports Management", value: "Sports Management" },
    { label: "Technical Consultancy", value: "Technical Consultancy" },
    { label: "Technical Services", value: "Technical Services" },
    { label: "Textile Trading", value: "Textile Trading" },
    { label: "Tourism & Recreation", value: "Tourism & Recreation" },
    { label: "Videography", value: "Videography" },
] as const

const premises = [
    { label: "Virtual Office", value: "Virtual Office" },
    { label: "Warehouse", value: "Warehouse" },
    { label: "Buisness Centre", value: "Buisness Centre" },
    { label: "Physical Office", value: "Physical Office" },
    { label: "Shop Front", value: "Shop Front" },
] as const

export default function ContactFormTwo() {
    const [formStep, setFormStep] = useState<number>(0)
    const [selectedCountryCode, setSelectedCountryCode] = useState<string>(''); // To store the selected country code
    const [activityValue, setActivityValue] = useState<string>('');
    const [premisesValue, setPremisesValue] = useState<string>('');
    const [numOwners, setNumOwners] = useState<number | ''>('');
    const [numVisas, setNumVisas] = useState<number | ''>('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [message, setMessage] = useState('');
    const [Submited, setSubmited] = useState<boolean>(false);
    const [isloading, setIsLoading] = useState<boolean>(false);

    const areFirstFourFieldsFilled = () => {
        return activityValue && premisesValue && numOwners !== '' && numVisas !== '';
    };

    function handlePhoneChange(e: any) {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, '');
        setPhone(numericValue);
    }

    const options = countries.map((country) => ({ value: country.value, label: country.value }));
    const handleCountryCodeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedCountryCode(event.target.value);
    };

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const formData = {
            name: name,
            countryCode: selectedCountryCode,
            phone: phone,
            email: email,
            service: selectedService,
            message: message,
            companyName: companyName,
            bussinessActivity: activityValue,
            premisesRequired: premisesValue,
            numberOfOwners: numOwners,
            numberOfVisas: numVisas,
        }
        setIsLoading(true)
        try {
            setIsLoading(true)
            const response = await axios.post('https://formserver.vercel.app/apcapitalscompanyformation', formData);
            console.log(response.data, ' and ', response.status)
            setIsLoading(false)
            setSubmited(true);
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    };

    if (Submited) {
        return (
            <div className="space-y-8 w-full bg-white py-10 px-5 border rounded-lg shadow-xl h-full">
                <p className="text-xl font-semibold text-center flex flex-col justify-between items-center gap-6">
                    <span>Form submitted successfully 👍👍</span>
                    Thanks for your interest 🎉🎉
                </p>
            </div>
        );
    }


    return (
        <>
            <form onSubmit={handleSubmit} className="flex flex-col gap-8 w-full py-10 border rounded-lg overflow-hidden text-sm lg:text-base text-white relative">
                <div className='bg-[#005577]/80 absolute top-0 left-0 z-[5] h-full w-full' />
                <img src="/assets/images/burj-khalifa.jpg" alt="" className='h-full w-full object-cover absolute top-0 left-0' />
                <h2 className='text-white text-2xl font-semibold md:text-5xl heading relative z-10 uppercase text-center'>get free quote now!</h2>
                <div className="flex transition-all duration-300 ease-in-out gap-5 px-5 w-full relative z-10" style={{ transform: `translateX(-${(formStep * 100)}%)` }}>
                    <div className="grow-0 shrink-0 w-full flex justify-around flex-col">
                        <div>
                            <label htmlFor="">Choose your business activity</label>
                            <select required name="bussinessActivity" tabIndex={-1} onChange={(e) => setActivityValue(e.target.value)} className="w-full outline-[#005977] text-[#005977] p-2 rounded-md">
                                <option value="" disabled selected>Select an activity</option>
                                {activity.map((data, index) => (<option key={index} value={data.value}>{data.label}</option>))}
                            </select>
                        </div>
                        <div>
                            <label htmlFor="">What type of permises would you require</label>
                            <select required name="premisesRequired" tabIndex={-1} onChange={(e) => setPremisesValue(e.target.value)} className="w-full outline-[#005977] text-[#005977] p-2 rounded-md">
                                <option value="" disabled selected>Select premises</option>
                                {premises.map((data, index) => (<option key={index} value={data.value} className=''>{data.label}</option>))}
                            </select>
                        </div>
                        <div className='flex flex-col'>
                            <label htmlFor="">Number of Owners (only numbers are allowed*)</label>
                            <input required type="number" name='numberOfOwners' tabIndex={-1} onChange={(e) => setNumOwners(Number(e.target.value))} className="outline-[#005977] text-[#005977] p-2 rounded-md" />
                        </div>
                        <div className='flex flex-col'>
                            <label htmlFor="">Number of Visas (only numbers are allowed*)</label>
                            <input required type="number" name='numberOfVisas' tabIndex={-1} onChange={(e) => setNumVisas(Number(e.target.value))} className="outline-[#005977] text-[#005977] p-2 rounded-md" />
                        </div>
                        <div onClick={() => {
                            if (areFirstFourFieldsFilled()) {
                                setFormStep(1);
                            } else {
                                alert('Please fill all required fields.');
                            }
                        }} className='bg-white w-full py-2 rounded-md text-center text-[#005577] text-lg font-semibold cursor-pointer select-none'>Next</div>
                    </div>
                    <div className='flex flex-col gap-5 w-full grow-0 shrink-0 ml-5'>
                        <input type="text" placeholder='Name' name='name' tabIndex={-1} value={name} onChange={(e) => setName(e.target.value)} className="outline-[#005977] text-[#005977] p-2 rounded-md w-full" />
                        <div className='flex flex-col md:flex-row gap-2 w-full'>
                            <Select
                                options={options}
                                value={countries.find((country) => country.value === selectedCountryCode)}
                                onChange={() => handleCountryCodeChange}
                                isSearchable
                                placeholder="Search Country Code"
                                className='text-[#005977]'
                                tabIndex={-1}
                            />
                            <input type="text" placeholder='Phone' tabIndex={-1} name='phone' value={phone} onChange={handlePhoneChange} className="outline-[#005977] text-[#005977] p-2 rounded-md md:w-[80%] w-full" />
                        </div>
                        <input type="text" placeholder='Email' name='email' tabIndex={-1} value={email} onChange={(e) => setEmail(e.target.value)} className="outline-[#005977] text-[#005977] p-2 rounded-md w-full" />
                        <input type="text" placeholder='Company Name' tabIndex={-1} name='companyName' value={companyName} onChange={(e) => setCompanyName(e.target.value)} className="outline-[#005977] text-[#005977] p-2 rounded-md w-full" />
                        <div className='flex flex-col'>
                            <label htmlFor="">Select any service</label>
                            <select required name="" tabIndex={-1} value={selectedService} onChange={(e) => setSelectedService(e.target.value)} className="outline-[#005977] text-[#005977] p-2 rounded-md w-full">
                                <option value="Buisness Setup">Buisness Setup</option>
                                <option value="Buisness Advices">Buisness Advices</option>
                                <option value="Financial Forecast and Projections">Financial Forecast and Projections</option>
                                <option value="Dubai Customs Registration">Dubai Customs Registration</option>
                                <option value="Tax Residency Certificate">Tax Residency Certificate</option>
                            </select>
                        </div>
                        <textarea name="message" tabIndex={-1} rows={3} placeholder='Message' value={message} onChange={(e) => setMessage(e.target.value)} className="border border-[#005977] focus-visible:ring-[#005977] text-[#005977] p-2 rounded-md w-full" />
                        <div onClick={() => setFormStep(0)} className='border-2 border-black hover:bg-[#9B999A] hover:text-gray-800 w-full py-2 rounded-md text-center text-black font-semibold cursor-pointer select-none'>previous</div>
                        <button tabIndex={-1} type='submit' className=' bg-white text-[#005577] text-lg w-full py-2 rounded-md flex gap-2 disabled:bg-gray-400 justify-center'>{isloading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}Submit</button>
                    </div>
                </div>
            </form>
        </>
    )
}
