import React, { useState } from 'react';
import './App.css';
import { ActivitySquare, ArrowRight, Building2, Facebook, Factory, FileCog, FileStack, Instagram, Lightbulb, Linkedin, Mail, Map, MapPin, MoveRight, Phone, ThumbsUp } from 'lucide-react';
import ContactForm from './components/contact-form';
import ContactFormTwo from './components/contact-form-two';
import PopUpForm from './components/popup-form';
// import ButtonPopUpForm from './components/button-popup-form';
import Select from 'react-select';
import { countries } from './components/countryCodes';
import axios from 'axios';
import { Loader2, X } from 'lucide-react';

const companies = ['Limited Liability Company (LLC)', 'Free Zone Companies', 'UAE Joint Stock Company', 'Trade Representative Office', 'Dual License Branch Office', 'Extension Branch Office']
const benefits = ["Tax Advantages", "Foreign Ownership", "World-Class Infrastructure", "Efficient Regulations", "Global Trade and Connectivity", "Strong Legal Framework", "Strategic Free Zones", "Residency Options", "Diverse Business Opportunities"]
const whyABC = ["Transparent Processes", "Expert Guidance", "Low operations", "Tax benefits", "No foreign exchange control", "Time and Cost Efficiency", "Customer-Centric Approach", "Clear Communication", "Focus on Results"]


function App() {

  const [isHovered1, setIsHovered1] = useState(false);
  const [isHovered3, setIsHovered3] = useState(false);
  const [zIndex, setZIndex] = useState(false);
  const [showForm, setShowForm] = useState<boolean>(false)
  const [isloading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [thanq, setThanq] = useState<boolean>(false)

  const [selectedCountryCode, setSelectedCountryCode] = useState<string>('');
  const options = countries.map((country) => ({ value: country.value, label: country.value }));

  const handleCountryCodeChange = (selectedOption: any) => {
    setSelectedCountryCode(selectedOption.value);
  };

  function handlePhoneChange(e: any) {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, '');
    setPhone(numericValue);
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    const formData = {
      name: name,
      countryCode: selectedCountryCode,
      phone: phone,
      email: email,
    }
    setIsLoading(true)
    try {
      setIsLoading(true)
      const response = await axios.post('https://formserver.vercel.app/apcapitalscompanyformation', formData);
      console.log(response.data, ' and ', response.status)
      setIsLoading(false)
      setThanq(true);

    } catch (error) {
      console.log(error)
      setIsLoading(false)
    }
  };

  const handleClickThanq = () => {
    setThanq(false)
  }

  const handleShowForm = () => {
    setShowForm(false)
    setZIndex(false)
  }

  const handleMouseEnter1 = () => {
    setIsHovered1(true);
  };

  const handleMouseLeave1 = () => {
    setIsHovered1(false);
  };

  const handleMouseEnter3 = () => {
    setIsHovered3(true);
  };

  const handleMouseLeave3 = () => {
    setIsHovered3(false);
  };

  // const handleMouseEnter4 = () => {
  //   setIsHovered4(true);
  // };

  // const handleMouseLeave4 = () => {
  //   setIsHovered4(false);
  // };
  // const handleMouseEnter5 = () => {
  //   setIsHovered5(true);
  // };

  // const handleMouseLeave5 = () => {
  //   setIsHovered5(false);
  // };

  return (

    <>

      <header>
        <nav className="bg-black bg-opacity-90 backdrop-blur-md border-gray-200 absolute top-0 left-0 z-50 w-full h-fit py-2">
          <div className="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-2">
            <div className='md:h-12 w-fit h-10 relative'>
              <img src="/assets/images/logo-2.png" alt="Logo" className='h-full w-full object-contain' />
            </div>
            <div>
              <div className='px-5 md:px-10 py-2 md:py-3 rounded-lg bg-[#005977] text-white text-sm md:text-base' onClick={() => setShowForm(true)}>{`Get Free Consultation`}</div>
            </div>
          </div>
        </nav>
      </header>

      <main>
        <PopUpForm />
        {showForm && <div className='fixed top-0 left-0 h-screen w-full flex justify-center items-center z-50 bg-[#005977]/75 px-[5%]'>
          {!thanq ?
            <form onSubmit={handleSubmit} className="space-y-8 w-full lg:w-1/3 xl:w-[25%] bg-black py-10 px-5 rounded-lg shadow-xl text-sm relative">
              <div className='flex flex-col gap-6 relative'>
                <span className='absolute -top-7 -right-3 text-white border-2 rounded-full p-1 cursor-pointer' onClick={handleShowForm}><X className='h-4 w-4' /></span>
                <div className='flex flex-col gap-4 items-center'>
                  <img src="/assets/images/logo-2.png" alt="" className='object-contain h-[50px]' />
                  <h2 className='text-2xl font-semibold text-white text-center uppercase lg:px-5'>We offer licences that support your business in UAE</h2>
                </div>
                <input required type='text' value={name} onChange={(e) => setName(e.target.value)} name='Name' placeholder='Name' className="focus-visible:ring-[#005977] text-[#005977] p-2 rounded-md outline-[#005977]" />
                <div className='flex flex-col gap-3 w-full'>
                  <Select
                    options={options}
                    value={options.find((country) => country.value === selectedCountryCode)}
                    onChange={handleCountryCodeChange}
                    isSearchable
                    placeholder="Search Country Code"
                  />
                  <input required type='tel' value={phone} onChange={handlePhoneChange} placeholder='Phone' name='phone' className="outline-[#005977] text-[#005977] p-2 rounded-md w-full" />
                </div>
                <input required type='text' value={email} onChange={(e) => setEmail(e.target.value)} name='Email' placeholder='email' className="outline-[#005977] text-[#005977] p-2 rounded-md" />
                <button disabled={isloading} type='submit' className='bg-white text-lg text-[#005577] font-medium w-full py-[10px] rounded-md flex gap-2 disabled:bg-gray-400 justify-center'>{isloading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}{`Submit`}</button>
              </div>
            </form> :
            <div className="space-y-8 lg:w-1/3 xl:w-[25%] h-fit bg-black py-10 px-5 border rounded-lg shadow-xl flex flex-col gap-5 items-center">
              <p className="text-xl text-white font-semibold text-center flex flex-col justify-between items-center gap-6">
                <span>Form submitted successfully 👍👍</span>
                Thanks for your interest 🎉🎉
              </p>
              <div onClick={handleClickThanq} className='px-10 py-2 text-lg w-fit texy-[#005977] bg-white rounded-md cursor-pointer'>
                {`Close`}
              </div>
            </div>
          }
        </div>}
        {/* section one  */}
        <section className="min-h-screen md:h-fit w-full relative bg-[url('C:\Users\kk203\Desktop\IGROW\ab-capitals-react\public\assets\images\bg-img.jpg')] bg-cover lg:px-[5%] xl:px-0">
          <div className='bg-[#005977] bg-opacity-[0.55] absolute top-0 left-0 h-full w-full z-0' />
          <div className='min-h-screen w-full max-w-7xl flex flex-col md:flex-row items-center justify-center gap-16 sm:gap-0 pt-[30%] md:pt-0 pb-10 md:pb-0 mx-auto'>
            <div className='w-[90%] sm:w-[65%] lg:w-3/4 relative z-10 md:relative flex flex-col gap-6 justify-center h-full'>
              <div className='w-2/3 sm:w-full lg:w-2/3 xl:w-1/2 mx-auto sm:mx-0'>
                <h1 className='text-white text-[28px] sm:text-3xl md:text-[56px] font-medium sm:font-semibold lg:font-bold text-center sm:text-start heading leading-[32px] sm:leading-[56px] lg:leading-[72px]'>{`Crafting Your Business Success Story in the UAE?`}</h1>
              </div>
              <p className='text-white text-base sm:text-xl sm:text-start sub-heading text-center lg:w-[55%]'>{`Fueling Your Business Growth with AB CAPITAL SERVICES Unlock opportunities, navigate challenges, and thrive with our strategic financial solutions.`}</p>
              <div className='flex items-center justify-center md:justify-start'>
                <a href={'#costcalci'}>
                  <button className="px-4 h-12 button-bg cursor-pointer rounded-3xl shadow-[0px_5px_0px_1px_#005577] group transition duration-300 ease-in-out">
                    <span className="font-medium text-white group-hover:text-white flex gap-12">{`Cost Calculator`} <MoveRight /></span>
                  </button>
                </a>
              </div>
            </div>
            <div className='relative z-10 sm:mt-10 rounded-md px-[10%] sm:px-[10%] md:px-0 w-full sm:w-[30%] lg:w-[26%] xl:w-[27%]' id='form'>
              <ContactForm />
            </div>
          </div>
        </section>
        {/* section two  */}
        <section className='w-[90%] sm:w-[80%] mx-auto my-20 flex flex-col gap-4'>
          <h2 className='text-[#005977] uppercase font-semibold sm:text-3xl text-xl text-center heading'>Company Formation in the UAE</h2>
          <p className='text-gray-700 text-center text-sm sm:text-base sub-heading flex flex-col gap-2'><span className='font-bold'>{`Are you looking to establish your business in the dynamic and thriving business landscape of the United Arab Emirates?`}</span> {`At AB CAPITAL SERVICES, we specialize in assisting entrepreneurs and investors like you in setting up your company with ease. In this guide, we'll explore three key options for company formation: Mainland and Freezone businesses, highlighting their differences and the unique benefits they offer.`}</p>
          <div className='w-full flex justify-evenly flex-wrap mt-6 gap-4'>
            <div onMouseEnter={handleMouseEnter1} onMouseLeave={handleMouseLeave1} className={`min-w-[280px] max-w-[300px] flex flex-col items-center gap-4 relative border rounded-lg p-6 overflow-hidden group/card select-none ${zIndex ? 'z-20' : 'z-0'}`}>
              <img src="/assets/images/card-bg-img.jpg" alt="" className='h-full w-full absolute top-0 left-0 object-cover hidden group-hover/card:block' />
              <div className='h-full w-full absolute top-0 left-0 bg-[#005977]/80 z-0 hidden group-hover/card:block' />
              <div className='bg-white rounded-full p-3 w-14 h-14 relative z-10'><img src="/assets/images/landmark.png" alt="" className='h-full w-full object-contain' /></div>
              <h3 className='text-black/80 text-center font-semibold heading relative z-10 group-hover/card:text-white'>{`Mainland Company Formation`}</h3>
              <p className='text-black/80 text-sm text-center sub-heading relative z-10 group-hover/card:text-white'>{`Mainland company formation in the UAE refers to establishing your business within the jurisdiction governed by the respective Emirates' Department of Economic Development (DED). These companies are also known as onshore companies.`}</p>
              <div className='flex gap-2 items-center justify-center text-[#005977] sub-heading bg-white rounded-lg w-full py-3 relative z-10 group-hover/card:font-semibold cursor-pointer' onClick={() => setShowForm(true)}>{`Get Free Consultation`} <ArrowRight /></div>
            </div>
            <div className='w-72 flex flex-col items-center gap-4 border rounded-lg p-6 relative z-10 overflow-hidden select-none'>
              <img src="/assets/images/card-bg-img.jpg" alt="" className={`h-full w-full absolute top-0 left-0 object-cover ${isHovered1 || isHovered3 ? 'hidden' : 'block'}`} />
              <div className={`h-full w-full absolute top-0 left-0 bg-[#005977]/80 z-0 ${isHovered1 || isHovered3 ? 'hidden' : 'block'}`} />
              <div className='bg-white rounded-full p-3 w-14 h-14 relative z-10'><img src="/assets/images/ratio.png" alt="" className='h-full w-full object-contain' /></div>
              <h3 className={`text-center font-semibold heading relative z-10 ${isHovered1 || isHovered3 ? 'text-black/80' : 'text-white'}`}>{`Free Zone Company Formation`}</h3>
              <p className={`text-sm text-center sub-heading relative z-10 ${isHovered1 || isHovered3 ? 'text-black/80' : 'text-white'}`}>{`Freezone company formation, on the other hand, involves setting up your business within designated free trade zones across the UAE. Each free zone is designed to cater to specific industries and business types.`}</p>
              <div className='flex gap-2 items-center justify-center text-[#005977] sub-heading bg-white rounded-lg w-full py-3 relative z-10 font-semibold cursor-pointer' onClick={() => setShowForm(true)}>{`Get Free Consultation`} <ArrowRight /></div>
            </div>
            <div onMouseEnter={handleMouseEnter3} onMouseLeave={handleMouseLeave3} className={`w-72 flex flex-col items-center gap-4 border rounded-lg p-6 relative z-0 overflow-hidden group/card select-none ${zIndex ? 'z-20' : 'z-0'}`}>
              <img src="/assets/images/card-bg-img.jpg" alt="" className='h-full w-full absolute top-0 left-0 object-cover hidden group-hover/card:block' />
              <div className='h-full w-full absolute top-0 left-0 bg-[#005977]/80 z-0 hidden group-hover/card:block' />
              <div className='bg-white rounded-full p-3 w-14 h-14 relative z-10'><img src="/assets/images/monitordot.png" alt="" className='h-full w-full object-contain' /></div>
              <h3 className='text-black/80 text-center font-semibold heading relative z-10 group-hover/card:text-white'>{`Off Shore Company Formation`}</h3>
              <p className='text-black/80 text-sm text-center sub-heading relative z-10 group-hover/card:text-white'>{`Offshore company formation in the UAE is easy and strategic, ensuring  a smooth setup for international business expansion.. AB Capital, with its expertise and support, facilitates the seamless creation of offshore companies in the UAE.`}</p>
              <div className='flex gap-2 items-center justify-center text-[#005977] sub-heading bg-white rounded-lg w-full py-3 relative z-10 group-hover/card:font-semibold cursor-pointer' onClick={() => setShowForm(true)}>{`Get Free Consultation`} <ArrowRight /></div>
            </div>
          </div>
        </section>
        {/* section three  */}
        <section className='my-20 py-10 relative bg-[#005577] bg-opacity-[0.05] h-full w-full overflow-x-hidden'>
          <div className='w-[90%] sm:w-[80%] mx-auto flex flex-col items-center gap-4 lg:gap-6 relative z-10'>
            <h2 className='text-white uppercase font-semibold sm:text-3xl text-xl text-center heading'>Business structured - Opening Company in Dubai</h2>
            <p className='text-white text-center sm:text-base text-sm sub-heading lg:w-[60%]'>Dubai Business Setup: Choose the Right Structure, Optimize Ownership, and Navigate Regulations with Expert Guidance for Success.</p>
            <div className='w-full grid grid-cols-2 sm:grid-cols-3 gap-x-6 gap-y-14 py-5'>
              {companies.map((item, index) =>
                <div key={index} className='p-2 sm:p-5 bg-white rounded-md relative group overflow-hidden'>
                  <div className='flex flex-col gap-2 items-center relative z-10'>
                    <MapPin className='sm:w-9 sm:h-9 w-6 h-6 text-[#005977] group-hover:text-white' />
                    <h3 className='font-bold text-gray-900 text-[10px] sm:text-base text-center group-hover:text-white'>{item}</h3>
                  </div>
                  <img src="/assets/images/bg-img-9.png" alt="" className='object-cover h-full w-full absolute top-0 left-0 z-0 hidden group-hover:block' />
                  <div className='h-full w-full bg-[#005977]/70 absolute top-0 left-0 z-0 hidden group-hover:block' />
                </div>)}
            </div>
          </div>
          <div className='absolute top-0 left-0 h-full w-full z-[5] bg-[#005977]/60' />
          <img src="/assets/images/bg-img-8.png" alt="" className='object-cover h-full w-full absolute top-0 left-0 z-0' />
          {/* <div className='absolute top-0 left-[90%] h-full w-full rounded-full bg-[#005977] bg-opacity-[0.23] bg-blur' /> */}
          {/* <div className='absolute top-0 right-[90%] h-full w-full rounded-full bg-[#005977] bg-opacity-[0.23] bg-blur' /> */}
        </section>
        {/* section four  */}
        <section className='w-[90%] sm:w-[80%] mx-auto my-20 hidden lg:block'>
          <div className=' flex flex-col items-center gap-6'>
            <h2 className='text-[#005977] uppercase font-semibold sm:text-[32px] text-xl text-center heading'>BUSINESS LICENSES FOR COMPANY FORMATION</h2>
            <p className='text-gray-700 text-center text-sm sm:text-base sub-heading lg:w-1/2'>company formation, obtaining the necessary business licenses is a crucial step to ensure legal compliance. </p>
            <div className='w-full md:flex gap-8 items-center'>
              <div className='w- h-fit flex flex-col gap-4 sm:gap-2 bg-[#005977] bg-opacity-[0.06] p-5 rounded-md items-center hover:bg-opacity-[0.2] transition-all ease-in duration-200'>
                <FileCog className='text-white lg:w-14 lg:h-14 xl:h-16 xl:w-16 sm:w-12 sm:h-12 w-10 h-10 bg-linear px-3 rounded-full' />
                <h3 className='text-gray-900 font-semibold sm:text-xl text-lg text-center'>Professional License</h3>
                <p className='text-gray-700 text-sm text-center'>
                  This license authorizes professionals to operate within their specialized fields. Typically renewed annually, the Professional License is a key requirement for ensuring legal compliance and conducting business activities in the United Arab Emirates.
                </p>
              </div>
              <div className='lg:w-[210%] xl:w-[120%] flex flex-col gap-4 sm:gap-2 bg-[#005977] bg-opacity-[0.06] p-5 rounded-md items-center hover:bg-opacity-[0.2] transition-all ease-in duration-200'>
                <Building2 className='text-white lg:w-14 lg:h-14 xl:h-16 xl:w-16 w-10 h-10 bg-linear px-3 rounded-full' />
                <h3 className='text-gray-900 font-semibold sm:text-xl text-lg text-center'>Commercial License</h3>
                <p className='text-gray-700 text-sm text-center'>
                  The Commercial License offered by ABCapital.ae is a vital authorization for businesses engaged in trading activities. Tailored to meet the specific needs of our clients, this license allows for the legal operation of commercial ventures, ensuring compliance with regulations set by the Department of Economic Development (DED) for mainland businesses or the respective Free Zone Authority for Free Zone companies.
                </p>
              </div>
              <div className='w- h-fit flex flex-col gap-4 sm:gap-2 bg-[#005977] bg-opacity-[0.06] p-5 rounded-md items-center hover:bg-opacity-[0.2] transition-all ease-in duration-200'>
                <Factory className='text-white lg:w-14 lg:h-14 xl:h-16 xl:w-16 w-10 h-10 bg-linear px-3 rounded-full' />
                <h3 className='text-gray-900 font-semibold sm:text-xl text-lg text-center'>Industrial License</h3>
                <p className='text-gray-700 text-sm text-center'>
                  The Industrial License from AbCapital.ae is your gateway to conducting manufacturing and industrial activities in the UAE. This license ensures regulatory compliance set by DED for mainland operations or the respective Free Zone Authority for Free Zone entities.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className='md:hidden w-[90%] mx-auto flex flex-col gap-6'>
          <div className='flex flex-col gap-3'>
            <h2 className='text-[#005977] uppercase font-semibold sm:text-3xl text-xl text-center heading'>BUSINESS LICENSES FOR COMPANY FORMATION</h2>
            <p className='text-gray-700 text-center text-sm sm:text-base sub-heading lg:w-1/2'>company formation, obtaining the necessary business licenses is a crucial step to ensure legal compliance. </p>
          </div>
          <div className='w-full flex gap-5 overflow-y-hidden overflow-x-scroll custom-scrollbar py-0'>
            <div className='max-w-[250px] h-fit flex flex-col gap-4 sm:gap-2 bg-[#005977] bg-opacity-[0.06] p-5 rounded-md items-center grow-0 shrink-0 hover:bg-opacity-[0.2] transition-all ease-in duration-200'>
              <FileCog className='text-white lg:w-14 lg:h-14 xl:h-16 xl:w-16 w-10 h-10 bg-linear px-2 rounded-full' />
              <h3 className='text-gray-900 font-semibold sm:text-xl text-lg text-center'>Professional License</h3>
              <p className='text-gray-700 text-sm text-center'>
                This license authorizes professionals to operate within their specialized fields. Typically renewed annually, the Professional License is a key requirement for ensuring legal compliance and conducting business activities in the United Arab Emirates.
              </p>
            </div>
            <div className='w-fit h-fit flex flex-col gap-4 sm:gap-2 bg-[#005977] bg-opacity-[0.06] p-5 rounded-md items-center grow-0 shrink-0 hover:bg-opacity-[0.2] transition-all ease-in duration-200'>
              <Building2 className='text-white lg:w-14 lg:h-14 xl:h-16 xl:w-16 w-10 h-10 bg-linear px-2 rounded-full' />
              <h3 className='text-gray-900 font-semibold sm:text-xl text-lg text-center'>Commercial License</h3>
              <p className='text-gray-700 text-sm text-center'>
                The Commercial License offered by ABCapital.ae is a vital authorization for businesses engaged in trading activities. Tailored to meet the specific needs of our clients, this license allows for the legal operation of commercial ventures, ensuring compliance with regulations set by the Department of Economic Development (DED) for mainland businesses or the respective Free Zone Authority for Free Zone companies.
              </p>
            </div>
            <div className='max-w-[250px] h-fit flex flex-col gap-4 sm:gap-2 bg-[#005977] bg-opacity-[0.06] p-5 rounded-md items-center grow-0 shrink-0 hover:bg-opacity-[0.2] transition-all ease-in duration-200'>
              <Factory className='text-white lg:w-14 lg:h-14 xl:h-16 xl:w-16 w-10 h-10 bg-linear px-2 rounded-full' />
              <h3 className='text-gray-900 font-semibold sm:text-xl text-lg text-center'>Industrial License</h3>
              <p className='text-gray-700 text-sm text-center'>
                The Industrial License from AbCapital.ae is your gateway to conducting manufacturing and industrial activities in the UAE. This license ensures regulatory compliance set by DED for mainland operations or the respective Free Zone Authority for Free Zone entities.
              </p>
            </div>
          </div>
        </section>
        {/* section five  */}
        <section className='h-full w-full py-10 px-[5%]'>
          <div className='h-full w-full max-w-7xl mx-auto lg:grid grid-cols-2 hidden'>
            <div className='mx-auto relative bg-shadow'>
              <div className='absolute -top-8 left-10 z-0 bg-[#0E242C] h-full w-3/4 rounded-md' />
              <img src="/assets/images/img-5.jpg" alt="" className='lg:h-[420px] xl:h-[460px] lg:w-[591px] w-full relative rounded-md' />
              <img src="/assets/images/star.png" alt="" className='lg:h-[100px] w-[100px] absolute -top-10 -left-10' />
            </div>
            <div className='w-[90%] sm:w-[80%] mx-auto flex flex-col gap-6'>
              <h2 className='text-[#005977] uppercase font-semibold sm:text-[32px] text-xl text-center lg:leading-[49px]'>We help you in step by step business setup process</h2>
              <p className='text-gray-700 text-center text-sm sm:text-base lg:text-lg lg:leading-[30px]'>Enjoy 100% foreign ownership in UAE!</p>
              <div className='w-full grid grid-cols-2 gap-x-6 gap-y-12 pt-8'>
                <div className='flex gap-3 items-center'>
                  <Lightbulb className='text-[#005977] sm:w-10 sm:h-10 w-8 h-8' />
                  <p className='font-semibold text-gray-800 sm:text-base text-sm'>Strategic Guidance</p>
                </div>
                <div className='flex gap-3 items-center'>
                  <ActivitySquare className='text-[#005977] sm:w-10 sm:h-10 w-8 h-8' />
                  <p className='font-semibold text-gray-800 sm:text-base text-sm'>Bank Account Opening</p>
                </div>
                <div className='flex gap-3 items-center'>
                  <FileStack className='text-[#005977] sm:w-10 sm:h-10 w-8 h-8' />
                  <p className='font-semibold text-gray-800 sm:text-base text-sm'>Legal Compliance</p>
                </div>
                <div className='flex gap-3 items-center'>
                  <Map className='text-[#005977] sm:w-10 sm:h-10 w-8 h-8' />
                  <p className='font-semibold text-gray-800 sm:text-base text-sm'>Location Selection</p>
                </div>
              </div>
            </div>
          </div>
          <div className='lg:hidden flex flex-col gap-4 items-center'>
            <h2 className='text-[#005577] text-3xl heading uppercase text-center font-medium leading-snug md:w-4/5'>We help you in step by step business setup process</h2>
            <p className='text-lg underline text-[#005577] text-center'>Enjoy 100% foreign ownership in UAE!</p>
            <div className='mx-auto relative my-8'>
              <div className='absolute -top-6 left-8 z-0 bg-[#0E242C] h-full w-3/4 rounded-md' />
              <img src="/assets/images/img-5.jpg" alt="" className='h-[250px] w-full relative rounded-md' />
              <img src="/assets/images/star.png" alt="" className='h-[50px] w-[50px] absolute -top-5 -left-5' />
            </div>
            <div className='w-full md:w-2/3 grid grid-cols-2 gap-x-4 gap-y-12'>
              <div className='flex gap-3 items-center'>
                <Lightbulb className='text-[#005977] sm:w-10 sm:h-10 w-8 h-8' />
                <p className='font-semibold text-gray-800 sm:text-base text-sm'>Strategic Guidance</p>
              </div>
              <div className='flex gap-3 items-center'>
                <ActivitySquare className='text-[#005977] sm:w-10 sm:h-10 w-8 h-8' />
                <p className='font-semibold text-gray-800 sm:text-base text-sm'>Bank Account Opening</p>
              </div>
              <div className='flex gap-3 items-center'>
                <FileStack className='text-[#005977] sm:w-10 sm:h-10 w-8 h-8' />
                <p className='font-semibold text-gray-800 sm:text-base text-sm'>Legal Compliance</p>
              </div>
              <div className='flex gap-3 items-center'>
                <Map className='text-[#005977] sm:w-10 sm:h-10 w-8 h-8' />
                <p className='font-semibold text-gray-800 sm:text-base text-sm'>Location Selection</p>
              </div>
            </div>
          </div>
        </section>
        {/* section six  */}
        <section className='mt-5 mb-10'>
          <div className='w-[90%] sm:w-[80%] mx-auto  flex flex-col gap-5 items-center'>
            <div className='flex gap-5 items-center flex-col'>
              <div className='sm:w-1/2 flex flex-col gap-4 items-center'>
                <h2 className='text-[#005577] uppercase font-semibold sm:text-[32px] text-2xl text-center heading leading-[36px] w-4/5 sm:w-full'>Working with Government Agency</h2>
                <p className='text-[#005577] text-center text-base underline leading-[24px] heading'>for a Better Future</p>
              </div>
              <div className='sm:w-2/3 lg:w-4/5'>
                <p className='text-sm text-[#374151] text-center w-full hidden lg:block sub-heading'>Our association with ministers and government entities throughout the UAE enables us to secure swift government approvals for our clients. Our consultants collaborate closely with the Department of Economic Development and various other governmental bodies in the UAE to facilitate seamless and prompt company formation.</p>
                <p className='text-sm text-[#374151] text-center w-full lg:hidden sub-heading'>Our association with ministers and government entities throughout the UAE enables us to secure swift government approvals for our clients.</p>
              </div>
            </div>
            <div className='flex items-center gap-5 justify-center w-full flex-wrap'>
              <img
                src="/assets/images/land-department.jpg" alt="logo"
                className='h-[150px] w-[150px] object-contain'
              />
              <img
                src="/assets/images/dubai-police.png" alt="logo"
                className='h-[150px] w-[150px] object-contain'
              />
              <img
                src="/assets/images/helth-authority.png" alt="logo"
                className='h-[150px] w-[150px] object-contain -mt-20 sm:mt-0'
              />
              <img
                src="/assets/images/dubai-economy.jpg" alt="logo"
                className='h-[150px] w-[150px] object-contain -mt-20 sm:mt-0'
              />
              <img
                src="/assets/images/dubai-courts.png" alt="logo"
                className='h-[150px] w-[150px] object-contain -mt-10 sm:mt-0'
              />
              <img
                src="/assets/images/duqe.png" alt="logo"
                className='h-[150px] w-[150px] object-contain -mt-5 sm:mt-0'
              />
              <img
                src="/assets/images/ifza.png" alt="logo"
                className='h-[150px] w-[150px] object-contain -mt-10 sm:mt-0'
              />
              <img
                src="/assets/images/jafza.png" alt="logo"
                className='h-[150px] w-[150px] object-contain -mt-5 sm:mt-0'
              />
              <img
                src="/assets/images/maydan.png" alt="logo"
                className='h-[150px] w-[150px] object-contain'
              />
              <img
                src="/assets/images/sharjah.png" alt="logo"
                className='h-[150px] w-[150px] object-contain'
              />
              <img
                src="/assets/images/dmcc.jpeg" alt="logo"
                className='h-[150px] w-[150px] object-contain'
              />
              <img
                src="/assets/images/kezad.jpeg" alt="logo"
                className='h-[150px] w-[150px] object-contain'
              />
              <img
                src="/assets/images/spc.jpeg" alt="logo"
                className='h-[150px] w-[150px] object-contain -mt-20 sm:mt-0'
              />
            </div>
            <div className='flex sm:gap-24 gap-12 flex-col sm:flex-row'>
              <div className='p-6 rounded-md overflow-hidden relative'>
                <div className={`flex flex-col gap-5 relative text-white ${zIndex ? 'z-20' : 'z-10'}`}>
                  <div className='flex flex-col items-center gap-4 pb-5 border-b'>
                    <ThumbsUp className='text-white sm:w-12 sm:h-12 w-10 h-10' />
                    <h3 className='font-semibold text-lg heading'>Benefits</h3>
                    <p className='text-lg text-center sm:text-right sub-heading'>setting up of businesses in UAE</p>
                  </div>
                  <div className='gap-10 flex flex-col justify-between px-6'>
                    <ul className="max-w-md space-y-1 list-disc list-inside heading">
                      {benefits.map((items, index) => <li key={index}>
                        {items}
                      </li>)}
                    </ul>
                    <div>
                      <button className='bg-white w-full py-2 rounded-md text-[#005577] font-bold' onClick={() => setShowForm(true)}>
                        Get Quote
                      </button>
                    </div>
                  </div>
                </div>
                <div className='bg-[#005577]/80 absolute top-0 left-0 z-[5] h-full w-full' />
                <img src="/assets/images/burj-khalifa.jpg" alt="" className='h-full w-full object-cover absolute top-0 left-0' />
              </div>
              <div className='p-6 rounded-md overflow-hidden relative'>
                <div className={`flex flex-col gap-5 relative text-white ${zIndex ? 'z-20' : 'z-10'}`}>
                  <div className='flex flex-col gap-4 items-center pb-5 border-b'>
                    <ThumbsUp className='sm:w-12 sm:h-12 w-10 h-10' />
                    <h3 className='font-semibold text-lg heading'>Why AB Capital?</h3>
                    <p className='text-lg text-center sm:text-right sub-heading'>setting up of businesses in UAE</p>
                  </div>
                  <div className='gap-10 flex flex-col px-6'>
                    <ul className="max-w-md space-y-1 list-disc list-inside heading">
                      {whyABC.map((items, index) => <li key={index}>
                        {items}
                      </li>)}
                    </ul>
                    <div>
                      <button className='bg-white w-full py-2 rounded-md text-[#005577] font-bold' onClick={() => setShowForm(true)}>
                        Get Free Consultation
                      </button>
                    </div>
                  </div>
                </div>
                <div className='bg-[#005577]/80 absolute top-0 left-0 z-[5] h-full w-full' />
                <img src="/assets/images/burj-khalifa.jpg" alt="" className='h-full w-full object-cover absolute top-0 left-0' />
              </div>
            </div>
          </div>
        </section>
        <section className=' bg-gray-100 mt-20 py-10 relative'>
          <div className='w-[90%] sm:w-[80%] lg:w-[60%] mx-auto  flex flex-col gap-4 relative z-10 text-white'>
            <p className='text-center text-lg sm:text-xl font-medium footer-text flex flex-col gap-4'><span className='font-bold'>Ready to take the next step in establishing your business in the UAE?</span> Contact us today to discuss your company formation needs, and let us assist you in making your entrepreneurial dreams a reality.</p>
          </div>
          <div className='bg-[#005577]/80 absolute top-0 left-0 z-[5] h-full w-full' />
          <img src="/assets/images/0681033110810b275c2e18c18d46e364.png" alt="" className='h-full w-full object-cover absolute top-0 left-0' />
        </section>
        <section className='my-6' id='costcalci'>
          <div className='p-4 mx-auto lg:w-1/2'>
            <div>
              <ContactFormTwo />
            </div>
          </div>
        </section>
      </main>

      <footer className='h-full w-full bg-black px-[5%] pt-8 pb-16'>
        <div className='h-full w-full max-w-7xl mx-auto grid sm:grid-cols-2 lg:grid-cols-5 gap-y-6 gap-x-8'>
          <div className='order-1'>
            <img src="/assets/images/logo-2.png" alt="" className="h-[60px] sm:h-[100px] lg:h-full w-full object-contain" />
          </div>
          <div className='flex flex-col gap-2 items-center justify-center order-3 sm:order-2'>
            <a href="tel:+971523655193" className='flex gap-2 items-center text-lg'> <Phone className='fill-[#005577]' /><span className='text-white'>+971-523655193</span></a>
            <a href="tel:+971585857119" className='flex gap-2 items-center text-lg'> <Phone className='fill-[#005577]' /><span className='text-white'>+971-585857119</span></a>
          </div>
          <div className='flex flex-col gap-2 items-center justify-center order-4 lg:order-3'>
            <a href="mailto:info@abcapital.ae" className='flex gap-2 items-center text-lg'><Mail className='fill-[#005577]' /><span className='text-white'>info@abcapital.ae</span></a>
          </div>
          <div className='w-full h-full order-5 lg:order-4'>
            <p className='text-white sm:text-[19px] font-medium heading text-center lg:text-start w-[90%] sm:w-full mx-auto'>Office No 404, Al Tawhidi Building, Bank Street, Bur Dubai, UAE</p>
          </div>
          <div className='flex gap-4 justify-center items-center order-2 sm:order-3 lg:order-5'>
            <a href="https://" className='bg-[#005577] rounded-md text-white h-fit w-fit p-2'><span><Instagram /></span></a>
            <a href="https://" className='bg-[#005577] rounded-md text-white h-fit w-fit p-2'><span className=''><Facebook /></span></a>
            <a href="https://" className='bg-[#005577] rounded-md text-white h-fit w-fit p-2'><span className=''><Linkedin /></span></a>
          </div>
        </div>


        {/* <div className='flex flex-col gap-5 lg:w-1/2 lg:justify-center lg:items-center'>
          <h2 className='text-[#005977] text-center font-semibold text-lg md:text-xl'>Reach Our Expert Team</h2>
          <div className='flex flex-col w-4/5 lg:items-center lg:justify-center sm:flex-row gap-5 lg:flex-col lg:w-fit mx-auto'>
            <div className='flex w-full flex-col justify-center items-center gap-3 border border-gray-800 p-1 rounded-md'>
              <Phone className='sm:w-12 sm:h-12 w-6 h-6 text-[#005977]' />
              <p className='text-xs lg:text-base text-gray-700 text-center font-semibold'><a href="tel:+971-523655193" className='cursor-pointer'>+971-523655193</a> <a href="tel:+971-585857119" className='cursor-pointer'>+971-585857119</a></p>
            </div>
            <div className='flex w-full flex-col items-center gap-3 border border-gray-800 p-1 rounded-md justify-center'>
              <Globe className='sm:w-12 sm:h-12 w-6 h-6 text-[#005977]' />
              <p className='text-xs lg:text-base font-semibold text-center text-gray-700'>info@abcapital.ae</p>
            </div>
            <div className='flex w-full flex-col items-center gap-3 border border-gray-800 p-1 rounded-md justify-center'>
              <Map className='sm:w-12 sm:h-12 w-6 h-6 text-[#005977]' />
              <p className='text-xs lg:text-base font-semibold text-center text-gray-700'>Office No 404, Al Tawhidi Building, Bank Street, Bur Dubai, UAE</p>
            </div>
          </div>
        </div> */}

      </footer>

    </>
  );
}

export default App;
