import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { countries } from './countryCodes';
import axios from 'axios';
import { Loader2, X } from 'lucide-react';

export default function PopUpForm() {
    const [isloading, setIsLoading] = useState<boolean>(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [timeoutForm, setTimeoutForm] = useState<boolean>(true)
    const [thanq, setThanq] = useState<boolean>(false)


    useEffect(() => {
        setTimeout(() => {
            setTimeoutForm(false)
        }, 12000);
    }, [])


    const [selectedCountryCode, setSelectedCountryCode] = useState<string>('');
    const options = countries.map((country) => ({ value: country.value, label: country.value }));

    const handleCountryCodeChange = (selectedOption: any) => {
        setSelectedCountryCode(selectedOption.value);
    };

    function handlePhoneChange(e: any) {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, '');
        setPhone(numericValue);
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const formData = {
            name: name,
            countryCode: selectedCountryCode,
            phone: phone,
            email: email,
        }
        setIsLoading(true)
        try {
            setIsLoading(true)
            const response = await axios.post('https://formserver.vercel.app/apcapitalscompanyformation', formData);
            console.log(response.data, ' and ', response.status)
            setIsLoading(false)
            setThanq(true);

        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    };

    const handleClickThanq = () => {
        setThanq(false)
        setTimeoutForm(true)
    }


    if (timeoutForm) return null;

    return (
        <div className='fixed top-0 left-0 h-screen w-full flex justify-center items-center z-[99] bg-[#005977]/75 px-[8%]'>
            {!thanq ?
                <form onSubmit={handleSubmit} className="space-y-8 w-full md:w-2/3 lg:w-1/2 xl:w-[30%] bg-black py-10 px-5 rounded-lg shadow-xl text-sm relative">
                    <div className='flex flex-col gap-6 relative'>
                        <span className='absolute -top-7 -right-3 text-white border-2 rounded-full p-1 cursor-pointer' onClick={() => setTimeoutForm(true)}><X className='h-4 w-4' /></span>
                        <div className='flex flex-col gap-4 items-center'>
                            <img src="/assets/images/logo-2.png" alt="" className='object-contain h-[50px]' />
                            <h2 className='text-xl md:text-2xl font-semibold text-white text-center uppercase lg:px-5'>We offer licences that support your business in UAE</h2>
                        </div>
                        <input required type='text' value={name} onChange={(e) => setName(e.target.value)} name='Name' placeholder='Name' className="focus-visible:ring-[#005977] text-[#005977] p-2 rounded-md outline-[#005977]" />
                        <div className='flex flex-col gap-3 w-full'>
                            <Select
                                options={options}
                                value={options.find((country) => country.value === selectedCountryCode)}
                                onChange={handleCountryCodeChange}
                                isSearchable
                                placeholder="Search Country Code"
                            />
                            <input required type='tel' value={phone} onChange={handlePhoneChange} placeholder='Phone' name='phone' className="outline-[#005977] text-[#005977] p-2 rounded-md w-full" />
                        </div>
                        <input required type='text' value={email} onChange={(e) => setEmail(e.target.value)} name='Email' placeholder='email' className="outline-[#005977] text-[#005977] p-2 rounded-md" />
                        <button disabled={isloading} type='submit' className='bg-white text-lg text-[#005577] font-medium w-full py-[10px] rounded-md flex gap-2 disabled:bg-gray-400 justify-center'>{isloading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}{`Submit`}</button>
                    </div>
                </form> :
                <div className="space-y-8 lg:w-1/3 xl:w-[25%] h-fit bg-black py-10 px-5 border rounded-lg shadow-xl flex flex-col gap-5 items-center">
                    <p className="text-xl text-white font-semibold text-center flex flex-col justify-between items-center gap-6">
                        <span>Form submitted successfully 👍👍</span>
                        Thanks for your interest 🎉🎉
                    </p>
                    <div onClick={handleClickThanq} className='px-10 py-2 text-lg w-fit texy-[#005977] bg-white rounded-md cursor-pointer'>
                        {`Close`}
                    </div>
                </div>
            }
        </div>
    )
}
