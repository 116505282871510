export const countries = [
    { value: "+93" },
    { value: "+355" },
    { value: "+213" },
    { value: "+1 684" },
    { value: "+376" },
    { value: "+244" },
    { value: "+1 264" },
    { value: "+6721" },
    { value: "+1 268" },
    { value: "+54" },
    { value: "+374" },
    { value: "+297" },
    { value: "+247" },
    { value: "+61" },
    { value: "+43" },
    { value: "+994" },
    { value: "+1 242" },
    { value: "+973" },
    { value: "+880" },
    { value: "+1 246" },
    { value: "+375" },
    { value: "+32" },
    { value: "+501" },
    { value: "+229" },
    { value: "+1 441" },
    { value: "+975" },
    { value: "+591" },
    { value: "+599" },
    { value: "+387" },
    { value: "+267" },
    { value: "+55" },
    { value: "+246" },
    { value: "+1 284" },
    { value: "+673" },
    { value: "+359" },
    { value: "+226" },
    { value: "+257" },
    { value: "+238" },
    { value: "+855" },
    { value: "+237" },
    { value: "+1" },
    { value: "+1 345" },
    { value: "+236" },
    { value: "+235" },
    { value: "+56" },
    { value: "+86" },
    { value: "+57" },
    { value: "+269" },
    { value: "+243" },
    { value: "+242" },
    { value: "+682" },
    { value: "+506" },
    { value: "+225" },
    { value: "+385" },
    { value: "+53" },
    { value: "+599" },
    { value: "+357" },
    { value: "+420" },
    { value: "+45" },
    { value: "+253" },
    { value: "+1 767" },
    { value: "+1 809" },
    { value: "+1 829" },
    { value: "+1 849" },
    { value: "+593" },
    { value: "+20" },
    { value: "+503" },
    { value: "+240" },
    { value: "+291" },
    { value: "+372" },
    { value: "+268" },
    { value: "+251" },
    { value: "+500" },
    { value: "+298" },
    { value: "+679" },
    { value: "+358" },
    { value: "+33" },
    { value: "+594" },
    { value: "+689" },
    { value: "+241" },
    { value: "+220" },
    { value: "+970" },
    { value: "+995" },
    { value: "+49" },
    { value: "+233" },
    { value: "+350" },
    { value: "+30" },
    { value: "+299" },
    { value: "+1 473" },
    { value: "+590" },
    { value: "+1 671" },
    { value: "+502" },
    { value: "+224" },
    { value: "+245" },
    { value: "+592" },
    { value: "+509" },
    { value: "+504" },
    { value: "+852" },
    { value: "+36" },
    { value: "+354" },
    { value: "+91" },
    { value: "+62" },
    { value: "+964" },
    { value: "+98" },
    { value: "+353" },
    { value: "+972" },
    { value: "+39" },
    { value: "+1 876" },
    { value: "+1 658" },
    { value: "+81" },
    { value: "+962" },
    { value: "+7" },
    { value: "+254" },
    { value: "+686" },
    { value: "+383" },
    { value: "+965" },
    { value: "+996" },
    { value: "+856" },
    { value: "+371" },
    { value: "+961" },
    { value: "+266" },
    { value: "+231" },
    { value: "+218" },
    { value: "+423" },
    { value: "+370" },
    { value: "+352" },
    { value: "+853" },
    { value: "+261" },
    { value: "+265" },
    { value: "+60" },
    { value: "+960" },
    { value: "+223" },
    { value: "+356" },
    { value: "+692" },
    { value: "+596" },
    { value: "+222" },
    { value: "+230" },
    { value: "+262" },
    { value: "+52" },
    { value: "+691" },
    { value: "+373" },
    { value: "+377" },
    { value: "+976" },
    { value: "+382" },
    { value: "+1 664" },
    { value: "+212" },
    { value: "+258" },
    { value: "+95" },
    { value: "+264" },
    { value: "+674" },
    { value: "+31" },
    { value: "+977" },
    { value: "+687" },
    { value: "+64" },
    { value: "+505" },
    { value: "+227" },
    { value: "+234" },
    { value: "+683" },
    { value: "+6723" },
    { value: "+850" },
    { value: "+389" },
    { value: "+44 28" },
    { value: "+1 670" },
    { value: "+47" },
    { value: "+968" },
    { value: "+92" },
    { value: "+680" },
    { value: "+970" },
    { value: "+507" },
    { value: "+675" },
    { value: "+595" },
    { value: "+51" },
    { value: "+63" },
    { value: "+48" },
    { value: "+351" },
    { value: "+1 787" },
    { value: "+1 939" },
    { value: "+974" },
    { value: "+262" },
    { value: "+40" },
    { value: "+7" },
    { value: "+250" },
    { value: "+590" },
    { value: "+290" },
    { value: "+1 869" },
    { value: "+1 758" },
    { value: "+590" },
    { value: "+508" },
    { value: "+1 784" },
    { value: "+685" },
    { value: "+239" },
    { value: "+966" },
    { value: "+221" },
    { value: "+381" },
    { value: "+248" },
    { value: "+232" },
    { value: "+1 721" },
    { value: "+65" },
    { value: "+421" },
    { value: "+386" },
    { value: "+677" },
    { value: "+252" },
    { value: "+27" },
    { value: "+82" },
    { value: "+211" },
    { value: "+34" },
    { value: "+94" },
    { value: "+249" },
    { value: "+597" },
    { value: "+46" },
    { value: "+41" },
    { value: "+963" },
    { value: "+886" },
    { value: "+992" },
    { value: "+255" },
    { value: "+66" },
    { value: "+670" },
    { value: "+228" },
    { value: "+690" },
    { value: "+676" },
    { value: "+1 868" },
    { value: "+216" },
    { value: "+90" },
    { value: "+993" },
    { value: "+1 649" },
    { value: "+688" },
    { value: "+256" },
    { value: "+380" },
    { value: "+971" },
    { value: "+44" },
    { value: "+1" },
    { value: "+598" },
    { value: "+998" },
    { value: "+678" },
    { value: "+58" },
    { value: "+84" },
    { value: "+1 340" },
    { value: "+681" },
    { value: "+970" },
    { value: "+967" },
    { value: "+260" },
    { value: "+263" }

]
