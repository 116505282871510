import React, { useState } from 'react';
import Select from 'react-select';
import { countries } from './countryCodes';
import axios from 'axios';
import { Loader2 } from 'lucide-react';

export default function ContactForm() {
    const [Submited, setSubmited] = useState<boolean>(false);
    const [isloading, setIsLoading] = useState<boolean>(false);
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    const [selectedCountryCode, setSelectedCountryCode] = useState<string>('');
    const options = countries.map((country) => ({ value: country.value, label: country.value }));

    const handleCountryCodeChange = (selectedOption: any) => {
        setSelectedCountryCode(selectedOption.value);
    };

    function handlePhoneChange(e: any) {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/\D/g, '');
        setPhone(numericValue);
    }

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        const formData = {
            name: name,
            countryCode: selectedCountryCode,
            phone: phone,
            email: email,
        }
        setIsLoading(true)
        try {
            setIsLoading(true)
            const response = await axios.post('https://formserver.vercel.app/apcapitalscompanyformation', formData);
            console.log(response.data, ' and ', response.status)
            setIsLoading(false)
            setSubmited(true);
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    };

    if (Submited) {
        return (
            <div className="space-y-8 w-full bg-[#005977]/60 py-10 px-5 border rounded-lg shadow-xl h-full">
                <p className="text-xl font-semibold text-center flex flex-col justify-between items-center gap-6">
                    <span>Form submitted successfully 👍👍</span>
                    Thanks for your interest 🎉🎉
                </p>
            </div>
        );
    }

    return (
        <>
            <form onSubmit={handleSubmit} className="space-y-8 w-full bg-[#005977]/60 py-10 px-5 rounded-lg shadow-xl text-sm">
                <div className='flex flex-col gap-5'>
                    <div>
                        <h2 className='text-3xl sm:text-[33px] xl:text-4xl font-bold text-white text-center'>Get Free <br /> Consultation</h2>
                    </div>
                    <input required type='text' value={name} onChange={(e) => setName(e.target.value)} name='Name' placeholder='Name' className="focus-visible:ring-[#005977] text-[#005977] p-2 rounded-md outline-[#005977]" />
                    <div className='flex flex-col gap-3 w-full'>
                        <Select
                            options={options}
                            value={options.find((country) => country.value === selectedCountryCode)}
                            onChange={handleCountryCodeChange}
                            isSearchable
                            placeholder="Search Country Code"
                        />
                        <input required type='tel' value={phone} onChange={handlePhoneChange} placeholder='Phone' name='phone' className="outline-[#005977] text-[#005977] p-2 rounded-md w-full" />
                    </div>
                    <input required type='text' value={email} onChange={(e) => setEmail(e.target.value)} name='Email' placeholder='email' className="outline-[#005977] text-[#005977] p-2 rounded-md" />
                    <button disabled={isloading} type='submit' className='bg-white text-lg text-[#005577] font-medium w-full py-[10px] rounded-md flex gap-2 disabled:bg-gray-400 justify-center'>{isloading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}{`Submit`}</button>
                </div>
            </form>
        </>
    );
}
